import React, {Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Header from '../Header/Header';
import Home from '../Home/Home';
import Contact from '../Contact/Contact';
import Gallery from '../Gallery/Gallery';
import Blog from "../Blog/Blog";
import Footer from "../footer/Footer";
import {Single} from "../Blog/Single";

class AppRouter extends React.Component{
    render() {

        return (

            <Fragment>
                <Router>
                    <Header/>
                    <Switch>
                        <Route path="/" component={Home} exact={true} />
                        <Route path="/blog" exact={true}  component={Blog}/>
                        <Route path="/blog/:id"  component={Single}/>
                        <Route path="/contact" component={Contact}/>
                        <Route path="/gallery" component={Gallery}/>
                    </Switch>
                    <Footer/>
                </Router>
            </Fragment>

        );
    }


};


export default AppRouter;