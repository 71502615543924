import React, {useState, useEffect, Fragment } from "react";
import axios from 'axios';
import Loader from "../loader/Loader";

export const Single = (props) => {

  const [post, setPost] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect( () => {

    setIsLoaded(false);
    axios.get(`http://goganapi.gogan.org.ng/wp-json/wp/v2/posts/${props.match.params.id}`)
        .then(res => {
          setPost(res.data);
          setIsLoaded(true);
        })
        .catch(err => {
          console.log(err);
        });

  } ,[]);

  if (!isLoaded){
    return <Loader/>
  }
  return (
    <Fragment>
      <section className="banner">
        <div className="container">
          <h1 className="subtitle">
            {post.title.rendered}
          </h1>
        </div>
      </section>
      <section className="blog-detail-section">
        <div className="container">
          <div className="row">
            <div className="col-md-9 offset-md-1">
              <div className="detail text-justify" dangerouslySetInnerHTML={{ __html:post.content.rendered }}>
            </div>
          </div>

          </div>
        </div>
      </section>
    </Fragment>
  );
};
