import React,{Fragment,useEffect,useState} from 'react'
import {Link} from "react-router-dom";
import Moment from 'react-moment';
import axios from 'axios';


const BlogItem =(props) =>  {
    const {id,title,excerpt, date} = props.blog;
    const [imgUrl,setImgUrl] = useState('');
    useEffect( () =>{
        const {featured_media} = props.blog;
        if (featured_media){
             axios.get(`http://goganapi.gogan.org.ng/wp-json/wp/v2/media/${featured_media}`).then( res =>{
                setImgUrl(res.data.media_details.sizes.full.source_url);
            } ).catch( err => {
                console.log(err);
            });
        }
    },[]);
    return (
        <div className="news-card">
            <header>
                <div className="img-holder">
                    <img src={imgUrl} className="img-fluid" alt=""/>
                </div>
            </header>
            <footer>
                <div className="headline-and-date">
                    <h4 className="headline">
                        <Link to={`/blog/${id}`}>{title.rendered}</Link>
                    </h4>
                    <h5 className="date">
                        <Moment format="YYYY/MM/DD">
                            {date}
                        </Moment>
                    </h5>
                </div>
                <div className="body">
                    <div dangerouslySetInnerHTML={{ __html:excerpt.rendered }}/>
                </div>
                <Link className="readmore" to={`/blog/${id}`}>Read more</Link>
            </footer>

        </div>
    )
};

export default  BlogItem;