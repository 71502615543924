import React,{Fragment,useState,useEffect} from 'react';
import axios from 'axios';
import BlogItem from "../BlogItem";
import Loader from "../loader/Loader";
import {Link} from "react-router-dom";

const BlogSection =() =>  {

    const [blog, setBlog] = useState({});
    const [isLoaded,setIsLoaded] = useState(false);

    useEffect( () => {
        const getPosts = async () => {
            setIsLoaded(false);
            axios.get('http://goganapi.gogan.org.ng/wp-json/wp/v2/posts?per_page=3')
                .then( res => {
                    setBlog(res.data);
                    setIsLoaded(true);
                })
                .catch( err =>{
                    console.log(err);
                } );
        };

        getPosts();
    }, []);


    if (isLoaded){
        return (
            <Fragment>
                <section className="news-section">
                    <div className="container">
                        <h2 className="title">News/Events</h2>
                        <div className="news-cards">

                            {blog.map( (item, index) => (
                                <BlogItem blog={item} key={index}/>
                            ) )}
                        </div>
                        <Link  to="/blog"  className="load-more">Load more</Link>
                    </div>
                </section>
            </Fragment>
        )
    }

    return <Loader/>

};

export default  BlogSection;