import React, {Component,Fragment} from 'react';
import './styles/base/_base.scss';
import AppRouter from './components/routes/AppRouter';


class App extends Component{


    componentDidMount(){
        let toggleBtn = document.getElementById('toggle-button');
        let navbar = document.getElementById('navbar-collapse');
        toggleBtn.addEventListener('click',function () {
            if (navbar.classList.contains('show')){
                navbar.classList.remove('show');
            } else {
                navbar.classList.add('show');
            }

        })
    }

  render() {



    return (
        <Fragment>
          <AppRouter/>
        </Fragment>
    );
  }
}
export default App;
