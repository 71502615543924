import React, {useEffect,useState,Fragment} from 'react';
import axios from 'axios';
import Loader from "../loader/Loader";
import BlogItem from "../BlogItem";
import Pagination from '../pagination';

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(6);

    useEffect(  () => {
        const  fetchPosts = () => {
            setIsLoaded(false);
            axios.get(`http://goganapi.gogan.org.ng/wp-json/wp/v2/posts`)
                .then(res => {
                    setPosts(res.data);
                    setIsLoaded(true);
                })
                .catch(err => {
                    console.log(err);
                });
        }

        fetchPosts();
    },[]);

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts     = posts.slice(indexOfFirstPost,indexOfLastPost);

    const paginate = pageNumber => setCurrentPage(pageNumber);

        if (isLoaded){
            return (
                <Fragment>
                    <section className="banner">
                        <div className="container">
                            <h1 className="title">Blog</h1>
                        </div>
                    </section>
                    <section className="blog-section">
                        <div className="container">
                            <div className="news-cards">
                                { currentPosts.map( ( item, index ) =>
                                    <BlogItem key={index} blog={item}/>
                                )}
                            </div>
                        </div>
                        <div className="container">
                            {
                                currentPosts.length > 5 &&  <Pagination postsPerPage={postsPerPage} totalPosts={posts.length} paginate={paginate}/>
                            }

                        </div>
                    </section>
                </Fragment>
            );
        }
        return (
            <div>
                <Loader/>
            </div>
        );
    }


export default Blog;