import React,{Fragment,useEffect,useState} from 'react'
import Moment from 'react-moment';
import axios from 'axios';


const GalleryImage =(props) =>  {
    const [imgUrl,setImgUrl] = useState('');
    useEffect( () =>{
        const {featured_media} = props.gallery;
        if (featured_media){
             axios.get(`http://goganapi.gogan.org.ng/wp-json/wp/v2/media/${featured_media}`).then( res =>{
                setImgUrl(res.data.media_details.sizes.full.source_url);
            } ).catch( err => {
                console.log(err);
            });
        }
    },[]);
    return (
        <img src={imgUrl}/>
    );

};

export default  GalleryImage;