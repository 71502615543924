import React, {Fragment} from 'react';
import {Link} from "react-router-dom";


const Footer = () => {

    return (
        <Fragment>
                <footer className="footer-wrapper">
                    <div className="container">
                        <div className="row add-clearfix same-height">
                            <div className="col-sm-6 col-md-5 about-brief">
                                <h5 className="footer-title box">Good Governance Ambassadors of Nigeria (GOGAN)</h5>
                                <p>Good Governance Ambassadors of Nigeria (GOGAN) is a non-governmental organization made up
                                    of
                                    patriotic Nigerians who are committed to advocacy for good governance, rule of law and
                                    adherence to democratic ethos. GOGAN will also strive to identify office holders who are
                                    models of good governance for special commendation and encouragement...</p>
                                <div className="social-icons">
                                    <a href="#" className="social-icon">
                                        <i className="fa fa-twitter has-circle" data-toggle="tooltip" data-placement="top"
                                           title="" data-original-title="Twitter"> </i></a>
                                    <a href="#" className="social-icon"><i className="fa fa-facebook has-circle"
                                                                           data-toggle="tooltip" data-placement="top"
                                                                           title="" data-original-title="Facebook"> </i></a>
                                    <a href="#" className="social-icon"><i className="fa fa-instagram has-circle"
                                                                           data-toggle="tooltip" data-placement="top"
                                                                           title="" data-original-title="Instagram"> </i></a>
                                </div>
                                <Link to="/contact"> Contact Us</Link>
                                <a href="#" className="back-to-top"><span></span></a>
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <h5 className="footer-title  box">Newsletter</h5>
                                <form method="form" action="">
                                    <div className="form-group">
                                        <input type="text" placeholder="Email Address"
                                               className="form-control input-text full-width"/>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn subscribe">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                            <div className="col-sm-6 col-md-3">
                                <h5 className="footer-title  box">Quick Links</h5>
                                <ul className="arrow useful-links">
                                    <li>
                                        <Link to="/" >Home</Link>
                                    </li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/blog">Blog</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="sub-footer">
                    <nav className="navbar navbar-expand-lg navbar-light bg-light d-none d-sm-block">
                        <div className="container">
                            <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"> </span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item active">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/blog">Blog</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/gallery" className="nav-link">Gallery</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/contact" className="nav-link">Contact</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link join" to="#">Join us</Link>
                                    </li>
                                </ul>

                                <Link to="/" className="navbar-brand">GOGAN</Link>

                            </div>
                        </div>

                    </nav>
                </div>

        </Fragment>
    );

}
export  default Footer;