import React, {Fragment} from 'react';
import Logo from '../../images/logo.png';
import {NavLink} from "react-router-dom";
import PropTypes from 'prop-types';

const Header = () => {
    return (
        <Fragment>
            <header className="header">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container">
                        <NavLink to="/" exact={true} className="navbar-brand">
                            <img src={Logo} alt="GOGAN"/>
                        </NavLink>
                        <button className="navbar-toggler" type="button" id="toggle-button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"> </span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbar-collapse">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <NavLink to="/" exact={true} className="nav-link">
                                        Home <span className="sr-only">(current)</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/blog" className="nav-link">
                                        Blog
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/gallery" className="nav-link">
                                        Gallery
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/contact" className="nav-link">
                                        Contact
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/join" className="nav-link join">
                                        Join us
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </Fragment>
    );
};

Header.propTypes = {};

export default Header;