import React, {Fragment, Component} from 'react';
import Slider2 from '../../images/slide.jpg';
import Slider1 from '../../images/slide1.jpg';
import $ from 'jquery';
import Swiper from  'swiper/js/swiper.esm.bundle';

class Slider extends Component{

    componentDidMount() {
        var galleryThumbs = new Swiper('.swiper-container.thumbnails', {
            spaceBetween: 0,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
        });


        var mainSlider = new Swiper('.swiper-container.main-slider',{
            speed: 1000,
            autoplay:{
                delay:5000
            },
            parallax: true,
            loop: true,
            grabCursor: true,
            centerSlides: true,
            autoHeight:true,
            slidesPerView: 1,
            pagination: {
                el: ".main-slider .swiper-pagination",
                type:'bullets',
                clickable: true
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper: galleryThumbs
            },
            on:{
                init:function(){
                    let swiper = this;
                    for(let i = 0; i < swiper.slides.length; i++ ){
                        $(swiper.slides[i]).find('.img-wrapper')
                            .attr({
                                'data-swiper-parallax':0.78*swiper.width,
                            });
                    }
                },
            }
        });
    }

    render() {
        return (
            <Fragment>
                <section className="slider">
                    <div className="swiper-container main-slider">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="img-wrapper">
                                    <img src={Slider2} alt="" />
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="img-wrapper">
                                    <img src={Slider1} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="swiper-button-prev swiper-button-white"></div>
                        <div className="swiper-button-next swiper-button-white"></div>
                    </div>

                    <div className="swiper-container thumbnails">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="img-wrapper">
                                    <img src={Slider2} alt="" />
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="img-wrapper">
                                    <img src={Slider1} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        )
    }
};


export default Slider;