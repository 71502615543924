import React, {Component,Fragment} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import GalleryImage from "./GalleryImage";

class Gallery extends Component {
    state = {
        galleries:[],
        isLoaded: false
    };

    componentDidMount() {
        axios.get('http://goganapi.gogan.org.ng/wp-json/wp/v2/gallery')
            .then(res => this.setState({
                galleries: res.data,
                isLoaded: true
            }))
            .catch(err => console.log(err));

    }


    render() {
        const {isLoaded,galleries} = this.state;
        const alphaArray = ['a','b','c','d','e','f','g','h','i','j','k'];
        if (isLoaded){
            return (
                <Fragment>

                    <section className="banner">
                        <div className="container">
                            <h1 className="title">Gallery</h1>
                        </div>
                    </section>
                    <section className="gallery-section">
                        <div className="container">
                            <div className="block">
                                <div className="">
                                    <h3 className="title">Photo Gallery</h3>
                                    <div className="photo-gallery-container">
                                        {galleries.map((gallery,index) => (
                                            <div className={`box ${alphaArray[index]}`} key={index}>
                                                <GalleryImage gallery={gallery}/>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </Fragment>
            );
        }

        return <div>
            <div className="p-5 text-justify text-center">
                <h1 className="p-5">Loading...</h1>
            </div>
        </div>
    }
}

Gallery.propTypes = {};

export default Gallery;