import React, {useEffect,useState,Fragment} from 'react';
import Google from './GoogleMap';
import axios from 'axios';


const Contact = () => {

    const [yourName,setYourName] = useState('');
    const [yourEmail,setYourEmail] = useState('');
    const [yourSubject,setYourSubject] = useState('');
    const [yourMessage,setYourMessage] = useState('');
    const [messageSent,setMessageSent] = useState(false);


    const data = {
        'your-name': yourName,
        'your-email': yourEmail,
        'your-subject': yourSubject,
        'your-message': yourMessage
    }

    const form = new FormData();
    form.append('your-name',data['your-name']);
    form.append('your-email',data['your-email']);
    form.append('your-subject',data['your-subject']);
    form.append('your-message',data['your-message']);


    const handleNameChange = (e) => {
        setYourName(e.target.value);
    };


    const handleEmailChange = (e) => {
        setYourEmail(e.target.value);
    }

    const handleSubjectChange = (e) => {
        setYourSubject(e.target.value);
    }

    const handleMessageChange = (e) => {
        setYourMessage(e.target.value);
    }

    useEffect(() => {
        console.log('changed');
    },[messageSent]);
    const submitForm = (e) => {
        e.preventDefault();
        axios.post('http://goganapi.gogan.org.ng/wp-json/contact-form-7/v1/contact-forms/43/feedback',form)
            .then(res => {
                console.log(res.data.status);
                if (res.data.status === 'mail_sent'){
                    setMessageSent(true)
                } 
            })
            .catch(err => {
                console.log(err);
            });
    };

    const track = (e) => {
        //let subBtn = document.getElementById('submit-btn');
        e.target.innerText = 'Sending...';
        if (messageSent){
            e.target.innerText = `Send message <i>&#129058;</i>`;
        }
    }


    return (
        <Fragment>
            <section className="banner">
                <div className="container">
                    <h1 className="title">Contact</h1>
                </div>
            </section>
            <section className="contact-section">
                <div className="container">
                    <div className="contact-form-and-address">
                        <div className="contact-form">
                            <h2 className="title">Keep n touch with us</h2>
                            <form onSubmit={submitForm}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="your-name" className="sr-only" >Full name</label>
                                        <input type="text" name="your-name" id="name" onChange={handleNameChange} className="form-control"
                                               placeholder="Full Name"/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="your-email" className="sr-only">Email</label>
                                        <input type="text" name="your-email" id="email"  onChange={handleEmailChange} className="form-control"
                                               placeholder="Email Address"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <label htmlFor="your-subject" className="sr-only">Subject</label>
                                        <input type="text" name="your-subject" id="subject" onChange={handleSubjectChange} className="form-control"
                                               placeholder="Subject"/>
                                    </div>
                                    <div className="form-group col-md-12">
                                        <label htmlFor="your-message" className="sr-only">Subject</label>
                                        <textarea name="your-message" id="message" onChange={handleMessageChange} className="form-control" rows="6"
                                                  placeholder="Message"> </textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" id="submit-btn" onClick={track} className="contact-btn">Send message <i>&#129058;</i></button>
                                        {messageSent && <p>your message was sent successfully.</p>}
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="address">
                            <h2 className="title">Contact details</h2>
                            <ul className="contact-info-list">
                                <li><a href="javascript:" id="getMap"> <i className="fa fa-map-marker"> </i> <span>6, Ademola Adetokunbo Crescent,
                            Maitama, Suite 301, Abuja-Nigeria</span> </a></li>
                                <li><a href=""> <i className="fa fa-phone"> </i> <span>+234 70545434</span> </a></li>
                                <li><a href=""> <i className="fa fa-envelope"> </i>
                                    <span>info@gogan.org.ng</span></a></li>
                            </ul>
                        </div>
                    </div>
                    {/*<div className="map" id="map"></div>*/}

                    <div className="map" style={{ position:'relative', display:'block', height:300+'px' }}>
                        <Google/>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Contact;