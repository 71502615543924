import React, {Component,Fragment} from 'react';


class AboutSection extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {

    }

    shouldComponentUpdate(nextProps, nextState) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <Fragment>
                <section className="mission-vision-section">
                    <div className="container" style={{ position: 'relative'}}>
                        <div className="video-holder">
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/7wwJCXgctvI"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="content">
                            <div className="democracy">
                                <h3>DEMOCRACY</h3>
                                <>We have all the attributes of a great nation. We are not there yet because the one
                                    commodity we have been unable to exploit to the fullest is unity of purpose.</>
                            </div>
                            <div className="change-we-need">
                                <h3>CHANGE WE NEED</h3>
                                <p>Change does not just happen. You and I and all of us must appreciate that we all have
                                    our part to play if we want to bring CHANGE about.</p>
                            </div>
                            <div className="future-of-nigeria">
                                <h3>FUTURE OF NIGERIA</h3>
                                <p>We shall quickly examine the best way to revive major industries and accelerate the
                                    revival and development of our railways, roads and general infrastructure.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </Fragment>
        );
    }
}


export default AboutSection;