import React, {Component} from 'react';
import {Map, GoogleApiWrapper,InfoWindow,Marker} from "google-maps-react";
import PropTypes from 'prop-types';

const mapStyles = {
    width: '100%',
    height: '100%'
};

class GoogleMap extends Component {
    constructor(props) {
        super(props);

    }


    componentDidMount() {

    }


    render() {
        return (
            <Map
                google={this.props.google}
                zoom={14}
                style={mapStyles}
                initialCenter={{
                    lat: 9.076830,
                    lng: 7.493785
                }}
            >

                <Marker/>
            </Map>

        );
    }
}

GoogleMap.propTypes = {};
export default GoogleApiWrapper({
    apiKey: 'AIzaSyAMYtrq0DdmHOO963wUuiLpvfjGpzpdvvo'
})(GoogleMap);