import React, { Fragment } from "react";
import Slider from "./Slider";
import AboutSection from "./AboutSection";
import BlogSection from "./BlogSection";

const Home = () => {
  return (
    <Fragment>
      <Slider />
      <section className="about-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="about-gogan">
                <h2 className="title">About GOGAN</h2>
                <div className="description">
                  <p>
                    Good Governance Ambassadors of Nigeria (GOGAN) is a
                    non-governmental organization made up of patriotic Nigerians
                    who are committed to advocacy for good governance, rule of
                    law and adherence to democratic ethos. GOGAN will also
                    strive to identify office holders who are models of good
                    governance for special commendation and encouragement.
                  </p>
                  <p>
                    Enamored by the rich credentials of President Muhammad
                    Buhari and his strong commitment to good governance, rule of
                    law and due process, and in recognition of President Buhari
                    as a beacon of good governance therefore undertake to
                    showcase his achievements and drum support for his
                    re-election in 2019 by mobilizing human and material
                    resources to achieve success.
                  </p>
                  <p>
                    GOGAN is committed to the promotion of peace, unity mutual
                    understanding and genuine reconciliation among party members
                    and more importantly to cooperate with the party leadership
                    at ensuring strict implementation of the party manifesto by
                    political office holders across all levels by encouraging
                    performance through awards and commendations.
                  </p>
                  <p>
                    We are therefore fully committed to promote the interest of
                    the APC in terms of winning elections, party discipline and
                    ensuring satisfactory fulfillment of campaign promises, as
                    well as implementation of party manifesto. This we shall
                    pursue through research dialogue and consultation with
                    critical stakeholders of the party.
                  </p>
                  <h3 className="promise-made">
                    PMB- Promise made, Promise Kept.
                  </h3>
                  <h3 className="chief">Chief Felix Idiga</h3>
                  <span className="chairman">
                    Chairman/National Coordinator GOGAN
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="tiles">
                <a
                  href="#"
                  className="tile a"
                  data-filter="winning-photos"
                  title=""
                >
                  <div className="wrap">
                    <div className="tile-face front">
                      <div className="tile-cover"> </div>
                    </div>
                    <div className="tile-face back">
                      <div className="tile-cover"> </div>
                    </div>
                  </div>
                </a>
                <a
                  href="#"
                  className="tile b"
                  data-filter="winning-photos"
                  title=""
                >
                  <div className="wrap">
                    <div className="tile-face front">
                      <div className="tile-cover"></div>
                    </div>
                    <div className="tile-face back">
                      <div className="tile-cover"></div>
                    </div>
                  </div>
                </a>
                <a
                  href="#"
                  className="tile c"
                  data-filter="winning-photos"
                  title=""
                >
                  <div className="wrap">
                    <div className="tile-face front">
                      <div className="tile-cover"></div>
                    </div>
                    <div className="tile-face back">
                      <div className="tile-cover"></div>
                    </div>
                  </div>
                </a>
                <a
                  href="#"
                  className="tile d"
                  data-filter="winning-photos"
                  title=""
                >
                  <div className="wrap">
                    <div className="tile-face front">
                      <div className="tile-cover"></div>
                    </div>
                    <div className="tile-face back">
                      <div className="tile-cover"></div>
                    </div>
                  </div>
                </a>
                <a
                  href="#"
                  className="tile e"
                  data-filter="winning-photos"
                  title=""
                >
                  <div className="wrap">
                    <div className="tile-face front">
                      <div className="tile-cover"></div>
                    </div>
                    <div className="tile-face back">
                      <div className="tile-cover"></div>
                    </div>
                  </div>
                </a>
                <a
                  href="#"
                  className="tile f"
                  data-filter="winning-photos"
                  title=""
                >
                  <div className="wrap">
                    <div className="tile-face front">
                      <div className="tile-cover"></div>
                    </div>
                    <div className="tile-face back">
                      <div className="tile-cover"></div>
                    </div>
                  </div>
                </a>
                <a
                  href="#"
                  className="tile g"
                  data-filter="winning-photos"
                  title=""
                >
                  <div className="wrap">
                    <div className="tile-face front">
                      <div className="tile-cover"></div>
                    </div>
                    <div className="tile-face back">
                      <div className="tile-cover"></div>
                    </div>
                  </div>
                </a>
                <a
                  href="#"
                  className="tile h"
                  data-filter="winning-photos"
                  title=""
                >
                  <div className="wrap">
                    <div className="tile-face front">
                      <div className="tile-cover"></div>
                    </div>
                    <div className="tile-face back">
                      <div className="tile-cover"></div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AboutSection />
      <BlogSection />
    </Fragment>
  );
};

export default Home;
