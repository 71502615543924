import React,{Fragment} from 'react';
import ImageLoader from '../../images/loader.gif';
const Loader = () => {
    return (
        <Fragment>
            <div className={"container py-5"}>
                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <img src={ImageLoader} className={"img-fluid"} alt=""/>
                    </div>
                    <div className={"col-md-4"}>
                        <img src={ImageLoader} className={"img-fluid"} alt=""/>
                    </div>
                    <div className={"col-md-4"}>
                        <img src={ImageLoader} className={"img-fluid"} alt=""/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
} ;
export default Loader;